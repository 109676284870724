* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 24px;
  color: aliceblue;
  font-weight: 600;
  text-shadow: 2px 2px 2px black;
}

header {
  text-align: center;
  background-color: rgb(24, 11, 165);
  padding: 4px 10%;
  margin-bottom: 50px;
}

.app {
  background: url(../src/images/sea.jpg);
  height: 100vh;
  background-repeat: no-repeat;
  background-size: cover; /* 画像をビューポート全体に広げます */
  background-position: center; /* 画像が中心に配置されるようにします */
}

.taskCard {
  width: 250px;
  padding: 10px 25px;
  margin: 10px 1%;
  background-color: rgb(228, 228, 228);
  border-radius: 5px;
}

.taskCardTitleInputArea {
  margin-bottom: 10px;
  width: 30%;
  cursor: pointer;
}

.taskCardTitleInput {
  width: 80px;
  font-size: 1.1rem;
  padding: 4px 6px;
  border-radius: 3px;
  border: none;
  outline: none;
}

.taskAddInput {
  width: 100%;
  font-size: 1.3rem;
  padding: 10px 15px;
  border: none;
  border-radius: 3px;
  outline: none;
}

.taskBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 17px 0;
  margin-top: 10px;
  background-color: white;
  border-radius: 5px;
  box-shadow: 1px 1px 1px 1px rgb(75, 75, 75);
}

.taskText {
  margin-left: 12px;
}
.taskTrashButton {
  margin-right: 9px;
  border: none;
  cursor: pointer;
}

.taskCardsArea {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}

.addTaskCardButtonArea {
  margin-left: 1%;
  margin-top: 25px;
}

.addTaskCardButton {
  border-radius: 50%;
  width: 30px;
  height: 30px;
  font-size: 24px;
  background-color: rgb(69, 159, 237);
  box-shadow: 2px 2px 4px 1px black;
  cursor: pointer;
  transition: all 0.3s;
}

.addTaskCardButton:hover {
  box-shadow: none;
  transform: translate(2px 2px);
}

.taskCardTitleAndTaskCardDeleteButtonArea {
  display: flex;
  justify-content: space-between;
}

.taskCardDeleteButton {
  border: none;
  background-color: rgb(228, 228, 228);
  font-size: 16px;
  cursor: pointer;
  color: rgb(158, 46, 46);
}
