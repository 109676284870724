.dragDropArea {
  text-align: center;
  margin: 50px;
  width: 450px;
}

.item {
  margin-bottom: 20px;
  font-size: 70px;
  border: 1px solid black;
}
